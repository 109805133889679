var envConfig = {
    searchAfterEnter: false,
    disableGlobalSearch: true,
    headerToolbar: {
        hideQueryContext: true,
        userPanel: {
            disable: false,
            hideLogout: false,
            hideLanguage: false
        },
        notifications: {
            disableSendMsgBtn: true
        }
    },
    debugMode: false,
    useNewGraphQLAPI: true,
    contextActionsRefetchIndicator: false,
    swingMode: false,
    timeZone: 'system',
    i18n: {
        usedLanguages: [
            {
                lang: 'en',
                name: 'English'
            },
            {
                lang: 'pl',
                name: 'Polski'
            }
        ],
        defaultLang: 'en'
    },
    enableChat: false,
    disableToolbarImages: true,
    helpUrl: '/web-help'
};

window.envConfig = envConfig;
